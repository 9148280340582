<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-select
          @input="handleFieldInput(COACH_FIELD_ALIASES.STATE_ADMIN, arguments[0])"
          :value="this[COACH_FIELD_ALIASES.STATE_ADMIN]"
          :items="stateAdminOptions"
          :rules="['required']"
          :placeholder="$t('fields.stateAssociationAffiliate')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getCoachInfoFormProps } from '@/utils/helpers/app/claimsUsasa';

export default {
  name: 'CoachInfo',
  mixins: [syncForm],
  props: {
    ...getCoachInfoFormProps(),
  },
  computed: {
    stateAdminOptions() {
      if (this.formParams.stateAdmins) {
        return this.formParams.stateAdmins.map(({ id, affiliate }) => ({
          text: affiliate,
          value: id,
        }));
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.disclaimer-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #464646;
}
</style>
